import dynamic from 'next/dynamic'
import CommunitySearch from './koodo/CommunitySearch/CommunitySearch'

export const blocks = {
  row: dynamic(() => import('./core/Row/ContentfulRow')),
  column: dynamic(() => import('./core/Column/ContentfulColumn')),

  box: dynamic(() => import('./core/Box/ContentfulBox')),
  buttonLink: dynamic(() => import('./core/ButtonLink/ContentfulButtonLink')),
  chevronLink: dynamic(() => import('./core/ChevronLink/ContentfulChevronLink')),
  link: dynamic(() => import('./core/Link/ContentfulLink')),
  icon: dynamic(() => import('./core/Icon/ContentfulIcon')),
  heading: dynamic(() => import('./core/Heading/ContentfulHeading')),
  text: dynamic(() => import('./core/Text/ContentfulText')),
  image: dynamic(() => import('./core/Image/ContentfulImage')),
  divider: dynamic(() => import('./core/Divider/ContentfulDivider')),
  spacer: dynamic(() => import('./core/Spacer/ContentfulSpacer')),
  callout: dynamic(() => import('./core/Callout/ContentfulCallout')),
  card: dynamic(() => import('./core/Card/ContentfulCard')),
  breadcrumbs: dynamic(() => import('./core/Breadcrumbs/ContentfulBreadcrumbs')),
  tabs: dynamic(() => import('./core/Tabs/ContentfulTabs')),
  dynamicContent: dynamic(() => import('./core/DynamicContent/ContentfulDynamicContent')),
  videoHosted: dynamic(() => import('./core/VideoHosted/ContentfulVideoHosted')),
  videoYouTube: dynamic(() => import('./core/VideoYouTube/ContentfulVideoYouTube')),
  storyCardBlock: dynamic(() => import('./BX/StoryCardBlock/ContentfulStoryCardBlock')),
  bxDonationCheckoutForm: dynamic(() => import('./BX/BXDonationCheckoutForm/ContentfulBXDonationCheckoutForm')),
  bxPushPoliticsWidget: dynamic(() => import('./BX/BXPushPoliticsWidget/ContentfulBXPushPoliticsWidget')),
  bxTextOverImageHero: dynamic(() => import('./BX/BXTextOverImageHero/ContentfulBXTextOverImageHero')),
  dropdownContentFilter: dynamic(() => import('./core/DropdownContentFilter/ContentfulDropdownContentFilter')),
  blockQuote: dynamic(() => import('./core/BlockQuote/ContentfulBlockQuote')),
  expandCollapse: dynamic(() => import('./core/ExpandCollapse/ContentfulExpandCollapse')),
  priceLockup: dynamic(() => import('./core/PriceLockup/ContentfulPriceLockup')),
  richText: dynamic(() => import('./core/RichText/ContentfulRichText')),
  testimonial: dynamic(() => import('./core/Testimonial/ContentfulTestimonial')),
  baseModal: dynamic(() => import('./core/BaseModal/ContentfulBaseModal')),
  videoPicker: dynamic(() => import('./core/VideoPicker/ContentfulVideoPicker')),
  list: dynamic(() => import('./core/List/ContentfulList')),
  navigationBar: dynamic(() => import('./core/NavigationBar/ContentfulNavigationBar')),
  notification: dynamic(() => import('./core/Notification/ContentfulNotification')),
  formBuilder: dynamic(() => import('./core/FormBuilder/ContentfulFormBuilder')),
  ribbon: dynamic(() => import('./core/Ribbon/ContentfulRibbon')),
  carouselUDS: dynamic(() => import('./core/Carousel/ContentfulCarousel')),
  quickLinks: dynamic(() => import('./core/QuickLinks/ContentfulQuickLinks')),
  badge: dynamic(() => import('./core/Badge/ContentfulBadge')),
  mediaReleaseArticleFilter: dynamic(() => import('./core/ArticleFilter/ContentfulArticleFilter')),
  a11yText: dynamic(() => import('./core/A11yText/ContentfulA11yText')),
  imageAccordion: dynamic(() => import('./core/ImageAccordion/ContentfulImageAccordion')),
  offersFilter: dynamic(() => import('./core/OffersFilter/ContentfulOffersFilter')),
  parallax: dynamic(() => import('./core/Parallax/ContentfulParallax')),
  countdown: dynamic(() => import('./core/Countdown/ContentfulCountdown')),
  backgroundImage: dynamic(() => import('./core/BackgroundImage/ContentfulBackgroundImage')),

  // business marketing
  multiLineDiscount: dynamic(() => import('./business_marketing/MultiLineDiscount/ContentfulMultiLineDiscount')),
  planCard: dynamic(() => import('./business_marketing/PlanCard/ContentfulPlanCard')),
  planFeature: dynamic(() => import('./business_marketing/PlanFeature/ContentfulPlanFeature')),
  stickyNavigationBar: dynamic(() => import('./business_marketing/StickyNavigationBar/ContentfulStickyNavigationBar')),
  mldButtonLink: dynamic(() => import('./business_marketing/MLDButtonLink/ContentfulMLDButtonLink')),

  // mobility
  tabNavigation: dynamic(() => import('./mobility/TabNavigation/ContentfulTabNavigation')),
  scrollBookmark: dynamic(() => import('./mobility/ScrollBookmark/ContentfulScrollBookmark')),
  table: dynamic(() => import('./mobility/Table/ContentfulTable')),
  bannerDrawer: dynamic(() => import('./mobility/bannerDrawer/ContentfulBannerDrawer')),
  backgroundBanner: dynamic(() => import('./mobility/BackgroundBanner/ContentfulBackgroundBanner')),
  buttonGroupTabs: dynamic(() => import('./mobility/ButtonGroupTabs/ContentfulButtonGroupTabs')),
  tfdCalculator: dynamic(() => import('./mobility/TFDCalculator/ContentfulTFDCalculator')),

  // home solutions
  priceToggle: dynamic(() => import('./home_solutions/PriceToggle/ContentfulPriceToggle')),
  imageShowcaseSection: dynamic(() => import('./home_solutions/ImageShowcaseSection/ContentfulImageShowcaseSection')),
  animatedTitleTrailer: dynamic(() => import('./home_solutions/AnimatedTitleTrailer/ContentfulAnimatedTitleTrailer')),
  carousel: dynamic(() => import('./home_solutions/Carousel/ContentfulCarousel')),
  animatedSuperhero: dynamic(() => import('./home_solutions/AnimatedSuperhero/ContentfulAnimatedSuperhero')),
  staggeredContent: dynamic(() => import('./home_solutions/StaggeredContent/ContentfulStaggeredContent')),
  animatedContentBackground: dynamic(
    () => import('./home_solutions/AnimatedContentBackground/ContentfulAnimatedContentBackground')
  ),
  accordionWithImage: dynamic(() => import('./home_solutions/AccordionWithImage/ContentfulAccordionWithImage')),
  expandCollapseMini: dynamic(() => import('./core/ExpandCollapseMini/ContentfulExpandCollapseMini')),
  planSection: dynamic(() => import('./home_solutions/PlanSection/ContentfulPlanSection')),
  stickyCart: dynamic(() => import('./home_solutions/StickyCart/ContentfulStickyCart')),
  eliModal: dynamic(() => import('./home_solutions/EliModal/ContentfulEliModal')),
  darkWebScan: dynamic(() => import('./home_solutions/DarkWebScan/ContentfulDarkWebScan'), { ssr: false }),
  darkWebScanV2: dynamic(() => import('./home_solutions/DarkWebScanV2/ContentfulDarkWebScanV2'), { ssr: false }),
  timeline: dynamic(() => import('./home_solutions/Timeline/ContentfulTimeline')),
  buttonLinkWrapper: dynamic(() => import('./home_solutions/ButtonLinkWrapper/ContentfulButtonLinkWrapper')),
  modalCheckboxForm: dynamic(() => import('./home_solutions/ModalCheckboxForm/ContentfulModalCheckboxForm')),
  dynamicCheckboxForm: dynamic(() => import('./home_solutions/DynamicCheckboxForm/ContentfulDynamicCheckboxForm')),
  modalFormSection: dynamic(() => import('./home_solutions/ModalFormSection/ContentfulModalFormSection')),
  productSelectorForm: dynamic(() => import('./home_solutions/ProductSelectorForm/ContentfulProductSelectorForm')),
  productSelector: dynamic(() => import('./home_solutions/ProductSelector/ContentfulProductSelector')),
  productPlanCard: dynamic(() => import('./home_solutions/ProductPlanCard/ContentfulProductPlanCard')),
  errorDisplay: dynamic(() => import('../components/errors/ContentfulErrorDisplay')),
  selectDropdown: dynamic(() => import('./home_solutions/SelectDropdown/ContentfulSelectDropdown')),
  dynamicProducts: dynamic(() => import('./home_solutions/DynamicProducts/ContentfulDynamicProducts')),
  formWithUrlParams: dynamic(() => import('./home_solutions/FormWithUrlParams/ContentfulFormWithUrlParams')),
  cart: dynamic(() => import('./home_solutions/Cart/ContentfulCart')),
  stickyCartNav: dynamic(() => import('./home_solutions/StickyNav/ContentfulStickyNav')),
  stickyCartV2: dynamic(() => import('./home_solutions/StickyCartV2/ContentfulStickyCartV2')),
  queryParameterConditionalDisplayer: dynamic(
    () => import('./home_solutions/QueryParameterConditionalDisplayer/ContentfulQueryParameterConditionalDisplayer')
  ),
  energySavingsCalculator: dynamic(
    () => import('./home_solutions/EnergySavingsCalculator/ContentfulEnergySavingsCalculator')
  ),
  savingsCalculator: dynamic(() => import('./home_solutions/SavingsCalculator/ContentfulSavingsCalculator')),
  comparisonTable: dynamic(() => import('./home_solutions/ComparisonTable/ContentfulComparisonTable')),

  // DCoE
  holidayDelighter: dynamic(() => import('./DCoE/HolidayDelighter/ContentfulHolidayDelighter')),

  // Health
  latestArticlesBlock: dynamic(() => import('./health/LatestArticlesBlock/ContentfulLatestArticlesBlock')),
  triageTabs: dynamic(() => import('./health/TriageTabs/ContentfulTriageTabs')),
  healthSlideshow: dynamic(() => import('./health/HealthSlideshow/ContentfulHealthSlideshow')),
  dynamicVideo: dynamic(() => import('./health/DynamicVideo/ContentfulDynamicVideo')),

  // Koodo
  communitySearch: dynamic(() => import('./koodo/CommunitySearch/ContentfulCommunitySearch')),

  // Annual Report Table
  annualReportTable: dynamic(() => import('./annualReport/annualReportTable/ContentfulAnnualReportTable')),
}
